<template>
  <v-container>
    <v-card class="mx-auto mt-10" width="800">
      <h2 class="pl-4 pt-6">
        {{$t('reset.change_password')}}
      </h2>
      <v-card-text>
        <v-form ref="form" @submit="acceptResetPasswrd">
          <v-text-field
            solo
            type="password"
            v-model="passwrd"
            :rules="passRules"
            :label="$t('password')"
          >
          </v-text-field>
          <v-text-field
            solo
            type="password"
            v-model="passwrd_again"
            :rules="passRules"
            :label="$t('password_again')"
          ></v-text-field>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn
          class="ml-2 mb-4"
          color="primary"
          @click="acceptResetPasswrd()"
        >
          {{$t('change')}}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  data: () => ({
    passwrd: '',
    passwrd_again: '',
    passRules: [
      v => !!v || 'Required',
      v => (v && v.length >= 8) || 'Min 8 characters',
    ],
  }),
  methods: {
    acceptResetPasswrd(e) {
      if(e) e.preventDefault();

      const val = this.$refs.form.validate()
      if(!val) return

      const code = this.$route.params.code

      this.getReset({
        code,
        passwrd: this.passwrd,
        passwrd_again: this.passwrd_again
      })
      .then(() => {
        this.success('Password changed!')
        this.$nextTick(() => {
          this.$router.push('/videos')
        })
      })
      .catch(e => { this.error(e) })
    },
    ...mapActions('auth', [
      'getReset',
    ]),
    ...mapActions('noti', [
      'error',
      'success'
    ])
  }
}
</script>